import React from 'react'
import { array, object, oneOfType, shape, string } from 'prop-types'
import { graphql } from 'gatsby'

import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import TeamRoll from '../components/TeamRoll'

export const InfoPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  return (
    <div>
      <section className="pt5 pt6-l bg-black cover" style={{ backgroundImage: 'url(/img/prole-bg.jpg)'}}>
        <div className="tc pv4 pv5-ns ph4">
          <h1 className="mt0 mb2 f1 white">
            {title}
          </h1>
        </div>
      </section>
      <section>
        <div className="mw7-ns center pa2 pa3-m pa4-l">
          <PageContent className="content" content={content} />
        </div>
      </section>
      <section className="bg-near-white">
        <div className="mw8-ns center pa2 pa3-m pa4-l">
          <h2 className="ttu pl3 mb2 mt2">Conheça o Time Prole Sports</h2>
          <TeamRoll />
        </div>
      </section>      
    </div>
  )
}

InfoPageTemplate.propTypes = {
  image: oneOfType([object, string]),
  title: string,
  heading: string,
  subheading: string,
  mainpitch: object,
  description: string,
  intro: shape({
    blurbs: array,
  }),
}

const InfoPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <InfoPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

InfoPage.propTypes = {
  data: shape({
    markdownRemark: shape({
      frontmatter: object,
    }),
  }),
}

export default InfoPage

export const infoPageQuery = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
